import PropTypes from "prop-types"
import React, { useEffect, useRef, useCallback, useState } from "react"

//Import Icons
import Icon from "@ailibs/feather-react-ts"

//Import Scrollbar
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link, useLocation } from "react-router-dom"

import withRouter from "../../../src/components/Common/withRouter"

import {
    getLoggedInUser,
    isUserAuthenticated,
} from "../../helpers/logged_in_user"

import {
    checkPermissions,
    haspermDisplayReports,
} from "src/helpers/permission_check"

const SidebarContent = (props: any) => {
    const ref: any = useRef()
    const activateParentDropdown = useCallback((item: any) => {
        //console.log("activateParentDropdown", item)
        item.classList.add("active")
        const parent = item.parentElement
        const parent2El = parent.childNodes[1]

        if (parent2El && parent2El.id !== "side-menu") {
            parent2El.classList.add("mm-show")
        }

        if (parent) {
            parent.classList.add("mm-active")
            const parent2 = parent.parentElement

            if (parent2) {
                parent2.classList.add("mm-show") // ul tag

                const parent3 = parent2.parentElement // li tag

                if (parent3) {
                    parent3.classList.add("mm-active") // li
                    parent3.childNodes[0].classList.add("mm-active") //a
                    const parent4 = parent3.parentElement // ul
                    if (parent4) {
                        parent4.classList.add("mm-show") // ul
                        const parent5 = parent4.parentElement
                        if (parent5) {
                            parent5.classList.add("mm-show") // li
                            parent5.childNodes[0].classList.add("mm-active") // a tag
                        }
                    }
                }
            }
            scrollElement(item)
            return false
        }
        scrollElement(item)
        return false
    }, [])

    const removeActivation = (items: any) => {
        //console.log("removeActivation", items)
        for (var i = 0; i < items.length; ++i) {
            var item = items[i]
            const parent = items[i].parentElement

            if (item && item.classList.contains("active")) {
                item.classList.remove("active")
            }
            if (parent) {
                const parent2El =
                    parent.childNodes &&
                    parent.childNodes.lenght &&
                    parent.childNodes[1]
                        ? parent.childNodes[1]
                        : null
                if (parent2El && parent2El.id !== "side-menu") {
                    //parent2El.classList.remove("mm-show");
                }

                parent.classList.remove("mm-active")
                const parent2 = parent.parentElement

                if (parent2) {
                    //parent2.classList.remove("mm-show");

                    const parent3 = parent2.parentElement
                    if (parent3) {
                        parent3.classList.remove("mm-active") // li
                        parent3.childNodes[0].classList.remove("mm-active")

                        const parent4 = parent3.parentElement // ul
                        if (parent4) {
                            //parent4.classList.remove("mm-show"); // ul
                            const parent5 = parent4.parentElement
                            if (parent5) {
                                //parent5.classList.remove("mm-show"); // li
                                parent5.childNodes[0].classList.remove(
                                    "mm-active"
                                ) // a tag
                            }
                        }
                    }
                }
            }
        }
    }

    const path = useLocation()
    const activeMenu = useCallback(() => {
        const pathName = path.pathname

        let matchingMenuItem = null
        const ul: any = document.getElementById("side-menu")
        const items = ul.getElementsByTagName("a")
        removeActivation(items)

        for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
        }
    }, [path.pathname, activateParentDropdown])

    useEffect(() => {
        ref.current.recalculate()
    }, [])

    useEffect(() => {
        new MetisMenu("#side-menu")
        activeMenu()
    }, [])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
        activeMenu()
    }, [activeMenu])

    const [hasPermDisplayReports, setHasPermissionsDisplayReports] =
        useState<boolean>(false)

    if (!hasPermDisplayReports) {
        checkPermissions(haspermDisplayReports, setHasPermissionsDisplayReports)
    }

    function scrollElement(item: any) {
        if (item) {
            const currentPosition = item.offsetTop
            if (currentPosition > window.innerHeight) {
                ref.current.getScrollElement().scrollTop = currentPosition - 300
            }
        }
    }

    const isUserAdmin = getLoggedInUser()?.role === "admin"

    return (
        <React.Fragment>
            <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{props.t("Menu")} </li>
                        {isUserAuthenticated ? (
                            <li>
                                <Link to="/dashboard">
                                    <Icon name="home" />
                                    <span>{props.t("Dashboard")}</span>
                                </Link>
                            </li>
                        ) : null}
                        {isUserAuthenticated ? (
                            <li>
                                <Link to="/presets">
                                    <Icon name="settings" />
                                    <span>{props.t("Presets")}</span>
                                </Link>
                            </li>
                        ) : null}
                        {isUserAuthenticated && hasPermDisplayReports ? (
                            <li>
                                {/* hack to make disabling enabling menu work */}
                                <a className="d-none" href="#"></a>
                                <Link to="/reports">
                                    <Icon name="file-text" />
                                    <span>{props.t("Reports")}</span>
                                </Link>
                                <ul className="sub-menu mm-show">
                                    <li className="mm-show">
                                        <Link to="/reports/operational">
                                            <span>
                                                {props.t("Operational")}
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="mm-show">
                                        <Link to="/reports/working">
                                            {props.t("Working")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        ) : null}
                        {isUserAdmin ? (
                            <li>
                                <a className="d-none" href="#"></a>
                                <Link to="/changes">
                                    <Icon name="archive" />
                                    <span>{props.t("Pending changes")}</span>
                                </Link>
                                <ul className="sub-menu mm-show">
                                    <li className="mm-show">
                                        <Link to="/changes/dcu">
                                            <span>
                                                {props.t("Dimming Curve")}
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="mm-show">
                                        <Link to="/changes/conf">
                                            {props.t("Configuration")}
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        ) : null}
                        {isUserAdmin ? (
                            <li>
                                <Link to="/device-raw-messages">
                                    <Icon name="cloud" />
                                    <span>{props.t("Messages")}</span>
                                </Link>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </SimpleBar>
        </React.Fragment>
    )
}

SidebarContent.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation()(withRouter(SidebarContent))
